<template>
    <div class="order-wrapper">
        <PageNavigationMenu
            v-if="individualOrderSettings && provideApp.$screenInnerWidth >= 1024"
            @linkClick="handleLinkClick"
            class="nav__wrapper"
            :buttons="buttonsLinks"
            :progress="progress"
            page-name="individualOrderKit"
            :selected-link="currentLink"
        />
        <IndividualOrderKit
            v-if="individualOrderSettings"
            class="order-kit__container"
            :current-link="currentLink"
            :individual-settings="individualOrderSettings"
        >
            <template #sideNav>
                <PageNavigationMenu
                    v-if="individualOrderSettings && provideApp.$screenInnerWidth < 1024"
                    @linkClick="handleLinkClick"
                    class="nav__wrapper"
                    :buttons="buttonsLinks"
                    :progress="progress"
                    page-name="individualOrderKit"
                    :selected-link="currentLink"
                />
            </template>
        </IndividualOrderKit>
    </div>
</template>

<script>
    import PageNavigationMenu from '@/components/Common/NavMenu/PageNavigationMenu';
    import IndividualOrderKit from '@/components/Order/Individual/IndividualOrderKit';
    import { mapGetters } from 'vuex';

    export default {
        name: 'IndividualOrderPreferences',
        components: { PageNavigationMenu, IndividualOrderKit },
        inject: ['provideApp'],
        data() {
            return {
                currentLink: null,
                buttons: [],
                filteredLinksData: [],
            };
        },
        computed: {
            ...mapGetters('mealKit', ['individualOrderSettings']),
            ...mapGetters('individualOrder', ['progress']),

            buttonsLinks() {
                let buttons = [
                    {
                        name: 'allergens',
                        title: this.$t('navbar.allergens'),
                        isHidden: !this.individualOrderSettings?.withAllergens,
                    },
                    {
                        name: 'dislikedProducts',
                        title: this.$t('navbar.dislikedProducts'),
                        isHidden: !this.individualOrderSettings?.withDislikedProducts,
                    },
                    {
                        name: 'macronutrients',
                        title: this.$t('navbar.macronutrients'),
                        isHidden: !this.individualOrderSettings?.withMacronutrients,
                    },
                    {
                        name: 'dietPlans',
                        title: this.$t('individualOrder.dietPlans'),
                        isHidden: !this.individualOrderSettings?.withDietPlans,
                    },
                    {
                        name: 'specificDishNumber',
                        title: this.$t('navbar.dishNumber'),
                        isHidden: !this.individualOrderSettings?.withMealNumber,
                    },
                    {
                        name: 'otherRequests',
                        title: this.$t('navbar.otherRequests'),
                        isHidden: false,
                    },
                ];

                return buttons.filter((button) => !button.isHidden);
            },
        },
        methods: {
            handleLinkClick(link) {
                this.currentLink = link;
            },
        },
        watch: {
            buttonsLinks: {
                handler(value) {
                    if (value.length) {
                        this.currentLink = this.buttonsLinks[0];
                    }
                },
                immediate: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .order-wrapper {
        @include column-align-stretch;

        padding: 1px;

        .order-kit__container {
            padding: 0 1px;
            padding-bottom: 30px;
            position: relative;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .nav__wrapper {
            margin-top: 10px;
            position: sticky;
            top: 90px;
            z-index: $index-500;
        }
    }

    @include media($lg) {
        .order-wrapper {
            @include row-align-start;

            padding: 0;

            .order-kit__container {
                max-height: calc(100vh - 192px);
                overflow-y: scroll;
                margin-left: 100px;
                width: 600px;
            }

            .nav__wrapper {
                margin-top: 0;
                min-width: 180px;
            }
        }
    }

    @include media($xl) {
        .order-wrapper {
            .order-kit__container {
                margin-left: 200px;
            }
        }
    }
</style>
